import * as React from "react";
import { Seo, Header, Card, Carousel, ProductsBanner } from "components";
import {
  colors,
  Cards,
  Section,
  Container,
  FullWidthContent,
  H2,
} from "styles";
import { graphql } from "gatsby";
import { Posture, WeighingScale, Stress, Rest } from "assets/svgs";

const EverydayPainPage = ({ data }) => {
  const { title, slides } = data.markdownRemark.frontmatter;
  return (
    <>
      <Seo title={title} />
      <Header
        data={data}
        title={title}
        content="<p>Many people experience muscle and joint pain in everyday life,<br /> often as a result of muscle tension and overexertion.</p>"
        alignBottom={true}
      />
      <Section>
        <Container>
          <FullWidthContent>
            <H2 $center>How to relieve pain?</H2>
          </FullWidthContent>
          <Carousel slides={slides} />
        </Container>
      </Section>
      <Section $bg={colors.lightGrey}>
        <Container>
          <FullWidthContent>
            <H2 $center style={{ maxWidth: "800px" }}>
              Causes of muscle and joint pain in everyday life
            </H2>
          </FullWidthContent>
          <Cards>
            <Card
              svg={<Posture />}
              title="Working position"
              text="Back and neck pain is often due to an incorrect working position"
            />
            <Card
              svg={<WeighingScale />}
              title="Overload"
              text="Overload and incorrect lifting technique can lead to back problems"
            />
            <Card
              svg={<Stress />}
              title="Stress"
              text="Stress may contribute to neck and shoulder pain"
            />
            <Card
              svg={<Rest />}
              title="Sleeping Position"
              text="Your sleeping position may lead to neck and shoulder pain"
            />
          </Cards>
        </Container>
      </Section>
      <Section>
        <ProductsBanner />
      </Section>
    </>
  );
};

export default EverydayPainPage;

export const query = graphql`
  query EverydayPainQuery {
    markdownRemark(frontmatter: { title: { eq: "Everyday Pain" } }) {
      frontmatter {
        title
        slides {
          id
          step
          title
          image {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          text
        }
      }
    }
    topFlame: file(relativePath: { eq: "background-top-flame.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    bottomFlame: file(relativePath: { eq: "background-bottom-flame.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
  }
`;
